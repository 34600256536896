import React from 'react';

const Close = ({ ...props }) => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.85358 2.49994L2.50002 2.85349L5.85879 6.21226L2.5 9.57104L2.85355 9.9246L6.21234 6.56581L9.57109 9.92456L9.92464 9.57101L6.56589 6.21226L9.92462 2.85353L9.57107 2.49998L6.21234 5.8587L2.85358 2.49994Z"
      fill="#1D1D1B"
      stroke="#1D1D1B"
      strokeWidth="0.5"
    />
  </svg>
);

export default Close;
