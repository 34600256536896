import React from 'react';
import classNames from 'classnames/bind';

import style from './Footer.module.scss';

const cx = classNames.bind(style);

const getYear = new Date().getFullYear();

const Footer = () => (
  <div className={cx('login-footer')} data-testid="login-footer">
    <p>
      &copy;
      {` ${getYear} River Island`}
    </p>
  </div>
);

export default Footer;
