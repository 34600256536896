import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import routes from '../../../routes';

const getBreadCrumb = (mode, pathname) => {
  const breadcrumb = Object.values(routes).filter(
    (route) => route.path === pathname && route,
  )[0]?.label;

  if (mode === 'account') {
    return ['MyAccount', breadcrumb];
  }
  if (mode === 'checkout') {
    return ['Checkout', breadcrumb];
  }
  return [breadcrumb];
};

const useUniversalVariable = (mode) => {
  const uvPageType = mode === 'checkout' ? 'Checkout' : 'Other';
  const { pathname } = useLocation();
  const currency = document.getElementById('currency-code')?.value;
  const language = document.getElementById('language')?.value;

  useEffect(() => {
    const uv = window.universal_variable;

    if (uv) {
      const breadcrumb = getBreadCrumb(mode, pathname);

      uv.basket = {
        currency,
        line_items: [],
        total: 0,
        subtotal: 0,
        tax: 0,
      };

      uv.user = {
        language,
        returning: false,
      };

      uv.page = {
        type: uvPageType,
        breadcrumb,
      };
    }
  }, [pathname, mode]);
};

export default useUniversalVariable;
