import React from 'react';
import classNames from 'classnames/bind';

import style from './Loading.module.scss';
import Spinner from '../Spinner/Spinner';

const cx = classNames.bind(style);

const Loading = () => (
  <div className={`${cx('loading')}`} data-testid="loading">
    <Spinner cssModifier="inOverlay" />
  </div>
);

export default Loading;
