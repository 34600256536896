import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import text from '../../text';
import Close from '../Icons/Close';
import RILogo from '../Icons/RILogo';

import { WEB_VIEW_PARAM } from '../../constants';
import { getMainSiteUrl } from '../../helpers/getMainSiteUrl';
import { useSessionToggle } from '../../hooks/useSessionToggle';
import Back from '../Icons/Back';
import style from './Header.module.scss';

const cx = classNames.bind(style);
const mainSiteUrl = getMainSiteUrl();
const { header } = text;

const Header = ({
  isOverlayHeader,
  isNotFoundHeader,
  buttonClickHandler,
  link,
}) => {
  const isWebview = useSessionToggle(WEB_VIEW_PARAM);

  const logo = (
    <RILogo
      className={cx('ri-logo', {
        'ri-logo-overlay': isOverlayHeader,
      })}
    />
  );

  return (
    <nav
      className={cx('login-header', {
        'login-header__transparent': isOverlayHeader,
        'login-header__center': isNotFoundHeader,
      })}
      data-testid="login-header"
    >
      {mainSiteUrl ? (
        <a
          href={mainSiteUrl}
          aria-label="River Island"
          data-item-name="logo"
          data-item-category="Navigation"
        >
          {logo}
        </a>
      ) : (
        logo
      )}

      {isOverlayHeader ? (
        <>
          {!isWebview && (
            <div className={cx('close-icon-wrapper')} data-testid="close-icon">
              <a href={mainSiteUrl} className={cx('close-icon')}>
                <Close />
                <span className={cx('icon-text')}>{header.exit}</span>
              </a>
            </div>
          )}
        </>
      ) : (
        !isNotFoundHeader && (
          <div className={cx('back-icon-wrapper')}>
            <Link to={link}>
              <button
                type="button"
                className={cx('back-icon')}
                onClick={() => {
                  buttonClickHandler();
                }}
              >
                <Back />
                <span className={cx('icon-text')}>{header.back}</span>
              </button>
            </Link>
          </div>
        )
      )}
    </nav>
  );
};

Header.propTypes = {
  isOverlayHeader: PropTypes.bool,
  buttonClickHandler: PropTypes.func,
  link: PropTypes.string,
  isNotFoundHeader: PropTypes.bool,
};

Header.defaultProps = {
  isOverlayHeader: false,
  buttonClickHandler: () => {},
  link: '',
  isNotFoundHeader: false,
};

export default Header;
