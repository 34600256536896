import { useFeatures } from '@paralleldrive/react-feature-toggles';
import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import PageWrapper from '../../components/PageWrapper';
import PageWrapperV2 from '../../components/PageWrapperV2';
import { UnderlinedLink } from '../../components/UnderlinedLink/UnderlinedLink';
import { LOGIN_V2 } from '../../constants';
import { getSiteUrl } from '../../helpers/getMainSiteUrl';
import text from '../../text';
import style from './ErrorPage.module.scss';

const cx = classNames.bind(style);

export function ErrorPage() {
  const mainSiteUrl = useMemo(getSiteUrl, []);
  const features = useFeatures();

  const { errorPage } = text;

  return features.includes(LOGIN_V2) ? (
    <PageWrapperV2 dataTestId="error-page">
      <h2 className={cx('title')}>{errorPage.title}</h2>
      <p className={cx('content')}>{errorPage.content}</p>

      <a
        href={mainSiteUrl}
        className={cx('button')}
        data-testid="error-page-link"
      >
        {errorPage.backToHome}
      </a>
    </PageWrapperV2>
  ) : (
    <PageWrapper heading="COMPUTER SAYS NO" dataTestId="error-page">
      <p data-testid="error-page-title">{errorPage.notFoundContent}</p>
      {mainSiteUrl && (
        <UnderlinedLink as="a" href={mainSiteUrl} data-testid="error-page-link">
          {errorPage.goToHomePage}
        </UnderlinedLink>
      )}
    </PageWrapper>
  );
}
