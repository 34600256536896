import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export function useQueryParams() {
  return queryString.parse(useLocation().search);
}

export function useReturnUrl() {
  const params = queryString.parse(useLocation().search, { decode: false });
  return params.returnUrl;
}

export function useResetPasswordToken() {
  return useQueryParams().token;
}

/**
 *  @returns {"checkout" | "account"}
 */
export function useMode() {
  const params = useQueryParams();
  const { mode } = queryString.parse(params.returnUrl);
  return mode === 'checkout' ? 'checkout' : 'account';
}
