import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import style from './PageWrapper.module.scss';

const cx = classNames.bind(style);

const PageWrapper = ({ heading, children, dataTestId }) => {
  return (
    <div data-testid={dataTestId} className={cx('pagewrapper', dataTestId)}>
      {heading && (
        <div className={cx('heading-container')}>
          <h1
            data-testid={`${dataTestId}-heading`}
            className={cx('pagewrapper__heading', `${dataTestId}-heading`)}
          >
            {heading}
          </h1>
        </div>
      )}

      <div
        className={cx('pagewrapper__content', dataTestId)}
        data-testid={`${dataTestId}-wrapper`}
      >
        {children}
      </div>
    </div>
  );
};

PageWrapper.propTypes = {
  heading: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};

PageWrapper.defaultProps = {
  heading: '',
  dataTestId: '',
  children: null,
};

export default PageWrapper;
