import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import hasPageScrolled from '../../helpers/header/hasPageScrolled';
import RILogo from '../Icons/RILogo';

import { getMainSiteUrl } from '../../helpers/getMainSiteUrl';
import style from './Header.module.scss';

const cx = classNames.bind(style);

const mainSiteUrl = getMainSiteUrl();
const Header = ({ isOverlayHeader }) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    hasPageScrolled(setHasScrolled);
  }, []);

  const logo = (
    <RILogo
      className={cx('ri-logo', {
        'ri-logo-overlay': isOverlayHeader,
      })}
    />
  );

  return (
    <nav
      className={cx('login-header', {
        'login-header__shadow': hasScrolled,
        'login-header__transparent': isOverlayHeader,
      })}
      data-testid="login-header"
    >
      {mainSiteUrl ? (
        <a
          href={mainSiteUrl}
          aria-label="River Island"
          data-item-name="logo"
          data-item-category="Navigation"
        >
          {logo}
        </a>
      ) : (
        logo
      )}
    </nav>
  );
};

Header.propTypes = {
  isOverlayHeader: PropTypes.bool,
};

Header.defaultProps = {
  isOverlayHeader: false,
};

export default Header;
