import { useEffect, useState } from 'react';

function useFeaturesTimeout(isExternalLoading) {
  const [hasTimedOut, setHasTimedOut] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setHasTimedOut(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  if (hasTimedOut || !isExternalLoading) return !isExternalLoading;
  return false;
}

export { useFeaturesTimeout };
