import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Link } from 'react-router-dom';
import style from './UnderlinedLink.module.scss';

const cx = classNames.bind(style);

export function UnderlinedLink({ as, children, variant, ...rest }) {
  const Component = as || Link;

  return (
    <Component className={cx('underlined-link', variant)} {...rest}>
      {children}
    </Component>
  );
}

UnderlinedLink.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  as: PropTypes.string,
};

UnderlinedLink.defaultProps = {
  variant: '',
  as: '',
};
