import React from 'react';

const Back = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.06061 5.46432C0.865775 5.66001 0.866475 5.9766 1.06217 6.17143L4.24336 9.33859L4.59691 8.98503L1.66609 6.06862H11.0759V5.56862H1.66375L4.59692 2.62101L4.24337 2.26746L1.06061 5.46432Z"
      fill="#5D5D5C"
      stroke="#5D5D5C"
      strokeWidth="0.5"
    />
  </svg>
);

export default Back;
