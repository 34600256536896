import debounce from 'lodash/debounce';

const hasPageScrolled = (setHasScrolled) => {
  window.addEventListener(
    'scroll',
    debounce(() => {
      setHasScrolled(window.pageYOffset !== 0);
    }, 25),
  );
};

export default hasPageScrolled;
