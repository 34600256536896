import './index.scss';

import 'custom-event-polyfill';
import '@ri-digital/web-data-layer';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AppProviders } from './AppProviders';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('login'),
);
