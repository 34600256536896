import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import style from './PageWrapper.module.scss';

const cx = classNames.bind(style);

const PageWrapper = ({ children, dataTestId }) => {
  return (
    <div data-testid={dataTestId} className={cx('pagewrapper', dataTestId)}>
      <div
        className={cx('pagewrapper__content', dataTestId)}
        data-testid={`${dataTestId}-wrapper`}
      >
        {children}
      </div>
    </div>
  );
};

PageWrapper.propTypes = {
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};

PageWrapper.defaultProps = {
  dataTestId: '',
  children: null,
};

export default PageWrapper;
