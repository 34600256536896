import { RECAPTCHA_DEV } from '../../constants/index';

export function getRecaptchaKey() {
  const input = document.getElementById('recaptcha-site-key');
  return input ? input.value : RECAPTCHA_DEV;
}

export const loadRecaptchaScript = (callback) => {
  const captchaScript = document.getElementById('recaptcha-script');
  if (!captchaScript) {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/enterprise.js';
    script.id = 'recaptcha-script';
    script.onload = () => {
      callback();
    };
    document.body.appendChild(script);
  }
};

function getWidget() {
  return document.querySelector('#recaptcha-checkbox');
}

export function renderCaptchaCheckbox() {
  if (!window.grecaptcha) {
    return;
  }
  const { grecaptcha } = window;
  grecaptcha.enterprise.ready(() => {
    grecaptcha.enterprise.render(getWidget(), {
      sitekey: getRecaptchaKey(),
    });
  });
}

export function getCaptchaResponse() {
  const { grecaptcha } = window;

  if (!getWidget() || !grecaptcha) {
    return null;
  }
  return grecaptcha.enterprise.getResponse();
}

export function resetCaptcha() {
  const { grecaptcha } = window;

  if (!getWidget() || !grecaptcha) {
    return;
  }
  grecaptcha.enterprise.reset();
}
