import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import style from './Spinner.module.scss';

const cx = classNames.bind(style);

const Spinner = ({ cssModifier }) => (
  <div data-testid="spinner" className={cx('spinner', '', cssModifier)} />
);

Spinner.propTypes = {
  cssModifier: PropTypes.oneOf(['inOverlay', 'inButton']).isRequired,
};

export default Spinner;
