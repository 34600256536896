/**
 * @returns {undefined | string}
 */
export function getMainSiteUrl() {
  const isWebsite =
    document.getElementsByName('client-id')?.[0]?.value === 'website';
  const url = document.getElementsByName('website-client-url')?.[0]?.value;
  return isWebsite && url ? url : undefined;
}

export function getSiteUrl() {
  const url = document.getElementsByName('website-client-url')?.[0]?.value;
  return url || undefined;
}
