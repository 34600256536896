module.exports = {
  login: { path: '/login', label: 'Sign In/ Sign Up' },
  passwordValidation: { path: '/login/password-validation', label: 'Sign In' },
  accountCreation: { path: '/login/account-creation', label: 'Register' },
  passwordSignUp: { path: '/login/password-signup', label: 'Register' },
  forgottenPassword: {
    path: '/login/forgotten-password',
    label: 'Forgotten Password',
  },
  resetPassword: { path: '/login/reset-password', label: 'Rest Password' },
  checkoutSignUp: { path: '/login/checkout-signup', label: 'Register' },
  notFound: { path: '/not-found', label: 'Not Found' },
};
