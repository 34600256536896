import { lazy } from 'react';

export const EmailValidationPage = lazy(() => import('./EmailValidationPage'));
export const PasswordValidationPage = lazy(() =>
  import('./PasswordValidationPage'),
);
export const AccountCreationPage = lazy(() => import('./AccountCreationPage'));
export const PasswordSignUpPage = lazy(() => import('./PasswordSignUpPage'));
export const ForgottenPasswordPage = lazy(() =>
  import('./ForgottenPasswordPage'),
);
export const ResetPasswordPage = lazy(() =>
  import('./ResetPasswordPage/ResetPasswordPage'),
);

export const CheckoutSignUpPage = lazy(() => import('./CheckoutSignUpPage'));
