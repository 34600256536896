import axios from 'axios';

export const httpClient = axios.create({
  baseURL: '/api',
});

httpClient.interceptors.response.use(
  (response) => response.data,
  (error) => {
    // eslint-disable-next-line no-throw-literal
    throw {
      ...error?.response?.data,
      status: error?.response?.status,
    };
  },
);

httpClient.interceptors.request.use((config) => {
  const token = document.querySelector(
    'input[name="__RequestVerificationToken"]',
  )?.value;

  return {
    ...config,
    headers: {
      ...config.headers,
      RequestVerificationToken: token,
    },
  };
});
