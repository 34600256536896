import { useCallback, useEffect, useState } from 'react';

function useActivateFeature(ftToggle, ftJson, activeFtList) {
  const [isActive, setIsActive] = useState(
    sessionStorage.getItem(ftToggle) === 'true',
  );

  const eventHandler = useCallback(() => {
    sessionStorage.setItem(ftToggle, true);
    setIsActive(true);
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return null;

    window.addEventListener(ftToggle, eventHandler);

    return () => {
      window.removeEventListener(ftToggle, eventHandler);
    };
  }, []);

  const isEnableExternalAuthentication =
    ftJson?.features?.find((f) => f.name === ftToggle)?.enabled || isActive;

  if (isEnableExternalAuthentication && !activeFtList.includes(ftToggle)) {
    activeFtList.push(ftToggle);
  }
}

export { useActivateFeature };
